import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import skipspaceLogo from "../images/Logo/skipspace_logo.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Modal from "react-modal";
import { getAuth, verifyBeforeUpdateEmail, onIdTokenChanged, EmailAuthProvider, reauthenticateWithCredential, signOut } from "firebase/auth";
import SignOutButton from './SignOutButton';


const formStyle = {
  maxWidth: "400px",
  margin: "0 auto",
  paddingTop: "25px",
  borderRadius: "5px",
  textAlign: "center",
};

const labelStyle = {
  display: "block",
  textAlign: "left",
  marginTop: "10px",
};

const buttonStyle = {
  width: "100%",
  padding: "10px",
  backgroundColor: "#003A49",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
};

const errorStyle = {
  color: "red",
  fontSize: "0.8em",
  textAlign: "left",
};

const ChangeEmailSchema = Yup.object().shape({
  newEmail: Yup.string()
  .email("Invalid email address")
  .matches(
    /^[a-zA-Z0-9._%+-]+@(skipspace\.co\.uk|shootthemoon\.co\.uk)$/g,
    "Your email must be specific to your council"
  )
    .required("New Email is required"),
});

function ChangeEmail() {
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [passwordForReauthentication, setPasswordForReauthentication] =
    useState("");

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handlePasswordChange = (event) => {
    setPasswordForReauthentication(event.target.value);
  };

  const handleReauthentication = async (values) => {
    try {
      // Create a credential with the entered password
      const credential = EmailAuthProvider.credential(
        user.email,
        passwordForReauthentication
      );

      // Reauthenticate the user with the credential
      await reauthenticateWithCredential(user, credential);

      // If reauthentication is successful, clear the error message
      setErrorMessage("");
      // Close the modal and retry the email update
      closeModal();
      return handleSubmit(values, true);
    } catch (reauthError) {
      console.error("Reauthentication failed: ", reauthError.message);
      setErrorMessage("Reauthentication failed. Please try again.");
      // Pass false to indicate reauthentication failure to handleSubmit
      return handleSubmit(values, false);
    }
  };

  const handleSubmit = async (values, reauthenticationSuccess = true) => {
    try {
      if (!user) {
        throw new Error("User not available");
      }
  
      if (reauthenticationSuccess) {
        // If reauthentication was successful, reset the password field
        setPasswordForReauthentication("");
      }
  
      // Check if the form is valid before proceeding
      const isFormValid = await ChangeEmailSchema.isValid(values);
      if (!isFormValid) {
        setErrorMessage("Invalid email. Please check again");
        return;
      }
  
      await verifyBeforeUpdateEmail(user, values.newEmail, {
        url: "https://www.skipspace.co.uk",
      });
  
      setSuccessMessage(
        "Verification email sent. Please check your new email for verification."
      );
  
      // Sign out the user
      await signOut(auth);
  
      // Navigate to the "/home" page
      navigate("/email-changed");
  
      const verificationTimeout = 2 * 60 * 1000; // 2 minutes
      await new Promise((resolve, reject) => {
        const unsubscribe = onIdTokenChanged(
          auth,
          (user) => {
            if (user && user.emailVerified) {
              setTimeout(() => {
                unsubscribe();
                resolve();
              }, verificationTimeout);
            }
          },
          reject
        );
      });
  
      setErrorMessage("");
    } catch (error) {
      console.error("Error updating email: ", error.message);
  
      if (
        error.code === "auth/requires-recent-login" &&
        reauthenticationSuccess
      ) {
        openModal();
        return;
      }
  
      setErrorMessage(
        "Email update failed. Please check your new email and try again."
      );
      setSuccessMessage("");
    }
  };
  

  const handleSignOut = () => {
    navigate('/skipspace-portal');
  };


  return (
    <div style={{ minHeight: "80vh" }}>
      <header className="header">
        <div>
          <img className="logo" src={skipspaceLogo} alt="Logo" />
        </div>
        <div>
        <SignOutButton onSignOut={handleSignOut} />
        </div>
      </header>
      <div className="change-email-page-container">
      <div className="back-to-dashboard">
        <Link className="text-underline" to="/council-account-details">
          <p>
            {" "}
            <span style={{ paddingRight: "5px" }}>
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </span>
            Back to Account Details
          </p>
        </Link>
      </div>
      <div>
        <Formik
          initialValues={{
            newEmail: "",
          }}
          validationSchema={ChangeEmailSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ handleChange, values }) => (
            <Form style={formStyle}>
              <div>
                <label htmlFor="newEmail" style={labelStyle}>
                  New Email
                </label>
                <Field
                  type="email"
                  name="newEmail"
                  className="registerInputStyle"
                  placeholder="New Email"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="newEmail"
                  component="div"
                  style={errorStyle}
                />
              </div>

              <button
                type="submit"
                style={buttonStyle}
                onClick={() => handleSubmit(values)}
              >
                Update Email
              </button>

              {successMessage && (
                <div style={{ color: "green" }}>{successMessage}</div>
              )}

              {errorMessage && (
                <div style={{ color: "red" }}>{errorMessage}</div>
              )}

              <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                contentLabel="Reauthentication Required"
              >
                <h2>Reauthentication Required</h2>
                <p>Please enter your password to continue.</p>
                <input
                  type="password"
                  value={passwordForReauthentication}
                  onChange={handlePasswordChange}
                />
                <button className="reauth-submit-button" onClick={() => handleReauthentication(values)}>
                  Submit
                </button>
                <button className="reauth-cancel-button" style={{marginTop:'15px'}} onClick={closeModal}>Cancel</button>
              </Modal>
            </Form>
          )}
        </Formik>
      </div>
      <div>
        <div style={{ textAlign: "center", paddingTop: "25px" }}>
          <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: "5px" }} />
          Upon email update, you will be signed out and required to verify your
          new email.
        </div>
      </div>
      </div>
    </div>
  );
}

export default ChangeEmail;
