import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Header from "../components/Header";
import TwoColumnIconLists from "../components/IconList";
import icon1 from "../images/OurImpact/Fly-tipping.png";
import icon2 from "../images/OurImpact/Waste management.png";
import icon3 from "../images/OurImpact/Fined.png";

function IconList({ items }) {
  const iconStyle = {
    width: "100px",
    display: "flex",
    margin: "0",
    padding: "0",
  };

  return (
    <ul style={{ listStyle: "none", marginRight: "0px", paddingLeft: "0" }}>
      {items.map((item, index) => (
        <li
          key={index}
          style={{
            margin: "0",
            padding: "0",
            display: "flex",
            alignItems: "center",
            paddingBottom: "10px",
          }}
        >
          <img src={item.icon} alt={item.text} style={iconStyle} />
          <span className="text">{item.text}</span>
        </li>
      ))}
    </ul>
  );
}

function OurImpact() {
  const HeadlineFactsList = [
    {
      icon: icon1,
      text: <div>The UK dumps 200 million tonnes of waste per year</div>,
    },
    {
      icon: icon2,
      text: (
        <div>
          In 2009, the government spent over 6 billion pounds for waste
          management, increasing by 1 billion pounds year-on-year since
        </div>
      ),
    },
    {
      icon: icon3,
      text: (
        <div>
          In 2021/22, councils dealt with almost 1.1 million incidents of
          fly-tipping and issued 91,000 fines, along with other enforcement
          actions
        </div>
      ),
    },
  ];

  return (
    <div>
      <Header />
      <div className="page-container">
        <div style={{ paddingBottom: "50px" }}>
          <h1 style={{ paddingBottom: "25px" }}>Cleaning up our boroughs</h1>
          <p>
            Repeat and potential offenders of fly-tipping is a pressing issue
            leading to substantial financial burdens on councils annually.
          </p>
          <p style={{ paddingBottom: "15px" }}>
            Councils can advertise locations with waste disposal availability,
            offering an alternative to offenders breaking the law and receiving
            fines.
          </p>
          <Link to="/our-solution">
            <p
              className="text-underline"
              style={{
                textDecoration: "underline",
                textUnderlinePosition: "under",
                textDecorationSkipInk: "none",
                fontWeight: "bold",
                color: "#000000",
              }}
            >
              Our Solution <FontAwesomeIcon icon={faArrowRight} />{" "}
            </p>
          </Link>
        </div>
        <div className="ourImpactColumnStyle page-padding">
          <div className="headlineFactsWidth">
            <h1>Headline Facts</h1>
            <IconList items={HeadlineFactsList} />
          </div>
          <div className="dividerStyle positiveImpactColumnStyle"></div>{" "}
          {/* Vertical divider placed here */}
          <div className="positive-impact-padding">
            <h1>Positive Impact</h1>
            <TwoColumnIconLists />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurImpact;
