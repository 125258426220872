import React from 'react';
import { Link } from 'react-router-dom';
import skipspaceLogo from '../images/Logo/skipspace_logo.svg';
import chamberCommerceLogo from '../images/Homepage/LCCI Proud to be a member Digital V1.png';

const Footer = () => {
    const currentYear = new Date().getFullYear(); // Dynamically get the current year

    return (
        <footer className="footer">
            <div>
                <img className='skipSpaceFooterLogo' src={skipspaceLogo} alt="Logo" />
            </div>
            <div>
         
            </div>
            <div>
                <div className='footer-text'>
                    <Link to="/privacy-policy" className='privacy-policy-text'>
                        Privacy Policy
                    </Link>
                    <p style={{ fontSize: '12px', color: 'white' }}>All Rights Reserved © {currentYear}</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;


/*
<img className='chamberLogo' src={chamberCommerceLogo} alt="Logo"/>
*/