import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Header from './Header';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'; 

const formStyle = {
  maxWidth: '400px',
  margin: '0 auto',
  padding: '20px',
  textAlign: 'left',
};

const buttonStyle = {
  width: '100%',
  padding: '10px',
  backgroundColor: '#003A49',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
};

const errorStyle = {
  color: 'red',
  fontSize: '12px',
  marginTop: '5px',
};

const successStyle = {
  color: 'green',
  fontSize: '12px',
  marginTop: '5px',
};

const emailRegex = /^[a-zA-Z0-9._%+-]+@(skipspace\.co\.uk|shootthemoon\.co\.uk)$/;

export function resetPassword(email) {
  const auth = getAuth(); 
  return sendPasswordResetEmail(auth, email);
}

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isEmailSent, setIsEmailSent] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setEmailError('Email is required');
    } else if (!emailRegex.test(email)) {
      setEmailError('Your email must be specific to your council');
    } else {
      setEmailError('');
    }

    if (email && emailRegex.test(email)) {
      try {
        await resetPassword(email);
        setIsEmailSent(true);
      } catch (error) {
        console.error('Error sending password reset email ', error);
        setEmailError('Invalid email');
      }
    }
  };

  return (
    <div>
      <div>
        <Header />
      </div>
      <div className='forgot-password-page-container'>
        <div className="back-to-dashboard">
          <Link className="text-underline" to="/skipspace-portal">
            <p>
              {" "}
              <span style={{ paddingRight: "5px" }}>
                <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
              </span>
              Back
            </p>
          </Link>
        </div>
        <div>
          <h1 style={{ textAlign: 'center' }}>Reset Password</h1>
        </div>
        <form style={formStyle} onSubmit={handleFormSubmit}>
          <div>
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your email"
              className="input-field"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button type="submit" style={buttonStyle}>
            Reset Password
          </button>
        </form>
        {emailError && <div style={errorStyle}>{emailError}</div>}
            {isEmailSent && <div style={successStyle}>Password reset email sent successfully!</div>}
      </div>
    </div>
  );
}

export default ForgotPassword;
