import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { db } from "./firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  where,
  setDoc,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";

const formStyle = {
  maxWidth: "400px",
  margin: "0 auto",
  padding: "20px 20px 20px 20px",
  borderRadius: "5px",
  textAlign: "center",
};

const labelStyle = {
  display: "block",
  textAlign: "left",
  marginTop: "10px",
};

const buttonStyle = {
  width: "100%",
  padding: "10px",
  backgroundColor: "#003A49",
  color: "white",
  border: "none",
  borderRadius: "5px",
  cursor: "pointer",
  marginTop: "15px",
};

const errorStyle = {
  color: "red",
  fontSize: "0.8em",
  textAlign: "left",
};

// Define the mapping between displayed text and values
const councilNameMapping = {
  barkinganddagenham: "Barking and Dagenham",
  barnet: "Barnet",
  bexley: "Bexley",
  brent: "Brent",
  bromley: "Bromley",
  camden: "Camden",
  croydon: "Croydon",
  ealing: "Ealing",
  enfield: "Enfield",
  greenwich: "Greenwich",
  hackney: "Hackney",
  hammersmithandfulham: "Hammersmith and Fulham",
  haringey: "Haringey",
  harrow: "Harrow",
  havering: "Havering",
  hillingdon: "Hillingdon",
  hounslow: "Hounslow",
  islington: "Islington",
  kensingtonandchelsea: "Kensington and Chelsea",
  kingstonuponthames: "Kingston upon Thames",
  lambeth: "Lambeth",
  lewisham: "Lewisham",
  merton: "Merton",
  newham: "Newham",
  redbridge: "Redbridge",
  richmonduponthames: "Richmond upon Thames",
  southwark: "Southwark",
  slough: "Slough",
  sutton: "Sutton",
  towerhamlets: "Tower Hamlets",
  walthamforest: "Waltham Forest",
  wandsworth: "Wandsworth",
  westminster: "Westminster",
};

const CouncilRegistrationSchema = Yup.object().shape({
  council_name: Yup.string().required("Council Borough is required"),
  council_contact_firstName: Yup.string().required("First Name is required"),
  council_contact_lastName: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .matches(
      /^[a-zA-Z0-9._%+-]+@(barkinganddagenham\.gov\.uk|barnet\.gov\.uk|bexley\.gov\.uk|brent\.gov\.uk|bromley\.gov\.uk|camden\.gov\.uk|croydon\.gov\.uk|ealing\.gov\.uk|enfield\.gov\.uk|greenwich\.gov\.uk|hackney\.gov\.uk|hammersmithandfulham\.gov\.uk|haringey\.gov\.uk|harrow\.gov\.uk|havering\.gov\.uk|hillingdon\.gov\.uk|hounslow\.gov\.uk|islington\.gov\.uk|kensingtonandchelsea\.gov\.uk|kingstonuponthames\.gov\.uk|lambeth\.gov\.uk|lewisham\.gov\.uk|merton\.gov\.uk|newham\.gov\.uk|redbridge\.gov\.uk|richmonduponThames\.gov\.uk|southwark\.gov\.uk|slough\.gov\.uk|sutton\.gov\.uk|towerhamlets\.gov\.uk|walthamforest\.gov\.uk|wandsworth\.gov\.uk|westminster\.gov\.uk|shootthemoon\.co\.uk|skipspace\.co\.uk)$/g,
      "Your email must be specific to your council and end in (.gov.uk)"
    )
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

/*
 /^[a-zA-Z0-9._%+-]+@(barkinganddagenham\.gov\.uk|barnet\.gov\.uk|bexley\.gov\.uk|brent\.gov\.uk|bromley\.gov\.uk|camden\.gov\.uk|croydon\.gov\.uk|ealing\.gov\.uk|enfield\.gov\.uk|greenwich\.gov\.uk|hackney\.gov\.uk|hammersmithandfulham\.gov\.uk|haringey\.gov\.uk|harrow\.gov\.uk|havering\.gov\.uk|hillingdon\.gov\.uk|hounslow\.gov\.uk|islington\.gov\.uk|kensingtonandchelsea\.gov\.uk|kingstonuponthames\.gov\.uk|lambeth\.gov\.uk|lewisham\.gov\.uk|merton\.gov\.uk|newham\.gov\.uk|redbridge\.gov\.uk|richmonduponThames\.gov\.uk|southwark\.gov\.uk|slough\.gov\.uk|sutton\.gov\.uk|towerhamlets\.gov\.uk|walthamforest\.gov\.uk|wandsworth\.gov\.uk|westminster\.gov\.uk|shootthemoon\.co\.uk)$/g,
      "Your email must be specific to your council and end in (.gov.uk)"
*/

function Register() {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [_registeredEmail, setRegisteredEmail] = useState(""); // eslint-disable-line no-unused-vars

  const initialValues = {
    council_name: "",
    council_contact_firstName: "",
    council_contact_lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = async (values) => {
    try {
      const auth = getAuth();
      const localCouncilsRef = collection(db, "councils");
      const displayedCouncilName = councilNameMapping[values.council_name];

      // Check if the council_name already exists as a field in the collection
      const existingFieldQuerySnapshot = await getDocs(
        query(
          localCouncilsRef,
          where("council_name", "==", displayedCouncilName)
        )
      );

      if (!existingFieldQuerySnapshot.empty) {
        setErrorMessage("Council borough is already registered.");
        setSuccessMessage("");
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        values.email,
        values.password
      );

      await sendVerificationEmail(userCredential.user);

      await updateProfile(userCredential.user, {
        displayName: values.council_name,
      });

      const councilBoroughRef = doc(localCouncilsRef, values.council_name);

      await setDoc(councilBoroughRef, {
        council_name: displayedCouncilName,
        council_contact_firstName: values.council_contact_firstName,
        council_contact_lastName: values.council_contact_lastName,
      });

      const linkedSkipCompaniesColRef = collection(
        councilBoroughRef,
        "linkedSkipCompanies"
      );
      const newDocRef = doc(linkedSkipCompaniesColRef);

      await setDoc(newDocRef, {});

      setRegisteredEmail(values.email);
      setSuccessMessage("Registration successful");
      setErrorMessage("");
      navigate("/verify-email", { state: { registeredEmail: values.email } });
    } catch (error) {
      console.error("Error creating user or adding document: ", error.message);
      setErrorMessage("Registration failed. Please try again.");
      setSuccessMessage("");
    }
  };

  const sendVerificationEmail = async (user) => {
    try {
      await sendEmailVerification(user);
    } catch (error) {
      console.error("Error sending email verification: ", error.message);
      throw new Error("Email verification failed. Please try again.");
    }
  };

  return (
    <div style={{ minHeight: "60vh" }}>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={CouncilRegistrationSchema}
          onSubmit={handleSubmit}
        >
          <Form style={formStyle}>
            <div>
              <label htmlFor="council_name" style={labelStyle}>
                Council Borough
              </label>
              <Field
                as="select"
                name="council_name"
                className="registerInputStyle"
              >
                <option value="" disabled>
                  Select Council Borough
                </option>
                {Object.entries(councilNameMapping).map(([value, text]) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </Field>
              <ErrorMessage
                name="council_name"
                component="div"
                style={errorStyle}
              />
            </div>

            <div>
              <label htmlFor="council_contact_firstName" style={labelStyle}>
                First Name
              </label>
              <Field
                type="text"
                name="council_contact_firstName"
                className="registerInputStyle"
              />
              <ErrorMessage
                name="council_contact_firstName"
                component="div"
                style={errorStyle}
              />
            </div>

            <div>
              <label htmlFor="council_contact_lastName" style={labelStyle}>
                Last Name
              </label>
              <Field
                type="text"
                name="council_contact_lastName"
                className="registerInputStyle"
              />
              <ErrorMessage
                name="council_contact_lastName"
                component="div"
                style={errorStyle}
              />
            </div>

            <div>
              <label htmlFor="email" style={labelStyle}>
                Email Address
              </label>
              <Field type="email" name="email" className="registerInputStyle" />
              <ErrorMessage name="email" component="div" style={errorStyle} />
            </div>

            <div>
              <label htmlFor="password" style={labelStyle}>
                Password
              </label>
              <Field
                type="password"
                name="password"
                className="registerInputStyle"
              />
              <ErrorMessage
                name="password"
                component="div"
                style={errorStyle}
              />
            </div>

            <div>
              <label htmlFor="confirmPassword" style={labelStyle}>
                Confirm Password
              </label>
              <Field
                type="password"
                name="confirmPassword"
                className="registerInputStyle"
              />
              <ErrorMessage
                name="confirmPassword"
                component="div"
                style={errorStyle}
              />
            </div>

            <button type="submit" style={buttonStyle}>
              Register
            </button>
            {successMessage && (
              <div style={{ color: "green" }}>{successMessage}</div>
            )}
            {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          </Form>
        </Formik>
      </div>
    </div>
  );
}

export default Register;
