import React from "react";
import Header from "../components/Header";
import Infographic from "../components/infographic";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const OurSolution = () => {
  const dividerStyle = {
    height: "5px",
    backgroundColor: "#003A49",
    margin: "40px 0",
  };

  const columnContainer = {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "30px",
  };

  const columnStyle = {
    flex: "auto",
    width: "calc(18% - 15px)",
    textAlign: "center",
  };

  const imageStyle = {
    width: "100%",
  };

  const textStyle = {
    padding: "50px 25px 0px 25px",
  };

  const infographicStyle = {
    paddingBottom: "75px", // Add bottom padding here
  };

  return (
    <div>
      <Header />
      <div style={infographicStyle}>
        <Infographic />
      </div>
      <Link to="/the-app">
            <p
              className="text-underline"
              style={{
                textDecoration: "underline",
                textUnderlinePosition: "under",
                textDecorationSkipInk: "none",
                fontWeight: "bold",
                color: "#000000",
                paddingLeft:"75px",
                paddingBottom:"75px"
              }}
            >
              Discover more about the app <FontAwesomeIcon icon={faArrowRight} />{" "}
            </p>
          </Link>
    </div>
  );
};

export default OurSolution;
