// Header.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom'; // Using NavLink instead of Link
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import skipspaceLogo from '../images/Logo/skipspace_logo.svg';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <header className="header">
    
      <div>
      <NavLink to="/" onClick={closeMobileMenu}>
        <img className="logo" src={skipspaceLogo} alt="Logo" />
      </NavLink>
      </div>
      <nav className={isMobileMenuOpen ? 'mobile-menu-open' : ''}>
        <div className="hamburger-menu" onClick={toggleMobileMenu}>
        <FontAwesomeIcon icon={isMobileMenuOpen ? faTimes : faBars} />
        </div>
        <ul className={`mobile-menu ${isMobileMenuOpen ? 'show-mobile-menu' : ''}`}>
          <li><NavLink to="/" onClick={closeMobileMenu}>Home</NavLink></li>
          <li><NavLink to="/our-impact" onClick={closeMobileMenu}>Our Impact</NavLink></li>
          <li><NavLink to="/our-solution" onClick={closeMobileMenu}>Our Solution</NavLink></li>
          <li><NavLink to="/the-app" onClick={closeMobileMenu}>The App</NavLink></li>
          <li><NavLink to="/contact" onClick={closeMobileMenu}>Contact</NavLink></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;

/*
       <li><NavLink to="/skipspace-portal" onClick={closeMobileMenu}>SKIPSPACE PORTAL</NavLink></li>
*/
