import React, { useEffect, useState } from "react";
import Video1 from "../videos-svg/Step1.mp4";
import Video2 from "../videos-svg/Step2.mp4";
import Video3 from "../videos-svg/Step3.mp4";
import Video4 from "../videos-svg/Step4.mp4";
import Video5 from "../videos-svg/Step5.mp4";
import Video6 from "../videos-svg/Step6.mp4";
import Video3mob from "../videos-svg/Step3-mobile.mp4";
import Video4mob from "../videos-svg/Step4-mobile.mp4";
import Step1 from "../videos-svg/Step1.png";
import Step2 from "../videos-svg/Step2.png";
import Step3 from "../videos-svg/Step3.png";
import Step4 from "../videos-svg/Step4.png";
import Step5 from "../videos-svg/Step5.png";
import Step6 from "../videos-svg/Step6.png";

function Infographic() {
  // State to track if the screen width is mobile-sized
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Update `isMobile` state on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize); // Cleanup on component unmount
  }, []);

  // Event handlers for mouse hover
  const handleMouseEnter = (e) => {
    e.target.play();
  };

  const handleMouseLeave = (e) => {
    e.target.pause();
    e.target.currentTime = 0; // Reset video to the start
  };

  return (
    <div>
      {/* Desktop layout */}
      {!isMobile && (
        <div className="video-grid">
          {/* First row */}
          <div className="video-container">
            <video
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              muted
              loop
            >
              <source src={Video1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step1} className="step1" alt="" />
          </div>
          <div className="separator">
            <div className="dashed-line" />
          </div>
          <div className="video-container">
            <video
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              muted
              loop
            >
              <source src={Video2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step2} className="step2" alt="" />
          </div>
          <div className="separator">
            <div className="dashed-line" />
          </div>
          <div className="video-container">
            <video
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              muted
              loop
            >
              <source src={Video3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step3} className="step3" alt="" />
          </div>

          {/* Middle row: vertical dashed line spanning from first column to last */}
          <div className="vertical-line-container">
            <div className="start-vertical-dashed-line"></div>
            <div className="vertical-dashed-line"></div>
            <div className="end-vertical-dashed-line"></div>
          </div>

          {/* Second row */}
          <div className="video-container">
            <video
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              muted
              loop
            >
              <source src={Video4} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step4} className="step4" alt="" />
          </div>
          <div className="separator">
            <div className="dashed-line" />
          </div>
          <div className="video-container">
            <video
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              muted
              loop
            >
              <source src={Video5} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step5} className="step5" alt="" />
          </div>
          <div className="separator">
            <div className="dashed-line" />
          </div>
          <div className="video-container">
            <video
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              muted
              loop
            >
              <source src={Video6} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step6} className="step6" alt="" />
          </div>
        </div>
      )}

      {/* Mobile layout */}
      {isMobile && (
        <div className="mobile-video-grid">
          {/* Video 1 */}
          <div className="video-container">
            <video
              muted
              autoPlay
              loop
              playsInline
            >
              <source src={Video1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step1} className="step1" alt="" />
          </div>
          <div className="mobile-separator">
            <div className="mobile-vertical-dashed-line" />
          </div>

          {/* Video 2 */}
          <div className="video-container">
            <video
              muted
              autoPlay
              loop
              playsInline
            >
              <source src={Video2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step2} className="step2" alt="" />
          </div>
          <div className="mobile-separator">
            <div className="mobile-vertical-dashed-line" />
          </div>

          {/* Video 3 */}
          <div className="video-container">
            <video
              muted
              autoPlay
              loop
              playsInline
            >
              <source src={Video3mob} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step3} className="step3" alt="" />
          </div>
          <div className="mobile-separator">
            <div className="mobile-vertical-dashed-line" />
          </div>

          {/* Video 4 */}
          <div className="video-container">
            <video
              muted
              autoPlay
              loop
              playsInline
            >
              <source src={Video4mob} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step4} className="step4" alt="" />
          </div>
          <div className="mobile-separator">
            <div className="mobile-vertical-dashed-line" />
          </div>

          {/* Video 5 */}
          <div className="video-container">
            <video
              muted
              autoPlay
              loop
              playsInline
            >
              <source src={Video5} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step5} className="step5" alt="" />
          </div>
          <div className="mobile-separator">
            <div className="mobile-vertical-dashed-line" />
          </div>

          {/* Video 6 */}
          <div className="video-container">
            <video
              muted
              autoPlay
              loop
              playsInline
            >
              <source src={Video6} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <img src={Step6} className="step6" alt="" />
          </div>
        </div>
      )}
    </div>
  );
}

export default Infographic;