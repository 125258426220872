import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import image1 from "../images/FigmaScreenshots/homescreen.png";
import image2 from "../images/FigmaScreenshots/search_for_skipspace.png";
import image3 from "../images/FigmaScreenshots/skipspace_results.png";
import image4 from "../images/FigmaScreenshots/selected_skipspace.png";
import image5 from "../images/FigmaScreenshots/confirmed_voucher.png";
import image6 from "../images/FigmaScreenshots/vouchers.png";
import image7 from "../images/FigmaScreenshots/active_voucher.png";
import image11 from "../images/FigmaScreenshots/activemenu.png";
import image12 from "../images/FigmaScreenshots/account_screen.png";
import Infographic from "../components/infographic";

const TheApp = () => {
  const dividerStyle = {
    height: "5px",
    backgroundColor: "#003A49",
    margin: "40px 0",
  };

  const columnContainer = {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "30px",
  };

  const columnStyle = {
    flex: "auto",
    width: "calc(18% - 15px)",
    textAlign: "center",
  };

  const imageStyle = {
    width: "100%",
  };

  const textStyle = {
    padding: "50px 25px 0px 25px",
  };

  return (
    <div>
      <Header />
      <div className="page-container">
        <div style={{ paddingBottom: "25px" }}>
          <h1 style={{ paddingBottom: "25px" }}>The App</h1>
          <p>The app will become 100% live and functional once councils sign  up, until then enjoy the website and spread the word.
          </p>
          <p>Follow the user experience below to find out how the app works.</p>
        </div>
        <div>
          <h1
            style={{
              paddingTop: "15px",
              paddingBottom: "25px",
              textAlign: "left",
            }}
          >
            User Experience -{" "}
            <span style={{ color: "rgb(122, 204, 0)" }}>
              Apply for a voucher
            </span>
          </h1>
          <div style={columnContainer}>
            <div className="first-column-style">
              <img src={image1} style={imageStyle} alt="Phone" />
              <p style={textStyle}>
                Select 'Search for SkipSpace' on the home screen
              </p>
            </div>
            <div className="first-column-style">
              <img src={image2} style={imageStyle} alt="Phone" />
              <p style={textStyle}>Select local borough from the list</p>
            </div>
            <div className="first-column-style">
              <img src={image3} style={imageStyle} alt="Phone" />
              <p style={textStyle}>Select a site address</p>
            </div>
            <div className="first-column-style">
              <img src={image4} style={imageStyle} alt="Phone" />
              <p style={textStyle}>Review your details and then click 'Next'</p>
            </div>
            <div className="first-column-style">
              <img
                className="voucher-confirmed"
                src={image5}
                style={imageStyle}
                alt="Phone"
              />
              <p style={textStyle}>Confirm your voucher</p>
            </div>
          </div>
          <div style={dividerStyle}></div>
        </div>
        <div>
          <h1 style={{ paddingBottom: "25px", textAlign: "left" }}>
            User Experience -{" "}
            <span style={{ color: "rgb(122, 204, 0)" }}>Active Voucher</span>
          </h1>
          <div style={{ ...columnContainer, justifyContent: "center" }}>
            <div className="first-column-style" style={{ flex: "auto" }}>
              <img src={image1} style={imageStyle} alt="Phone" />
              <p style={textStyle}>Select 'View Vouchers' on the home screen</p>
            </div>
            <div className="first-column-style" style={{ flex: "auto" }}>
              <img src={image6} style={imageStyle} alt="Phone" />
              <p style={textStyle}>Select active voucher to see details</p>
            </div>
            <div className="first-column-style" style={{ flex: "auto" }}>
              <img
                className="voucher-confirmed"
                src={image7}
                style={imageStyle}
                alt="Phone"
              />
              <p style={textStyle}>Active voucher details are displayed</p>
            </div>
            <div
              className="hidden-columns"
              style={{ ...columnStyle, flex: "auto" }}
            ></div>
            <div
              className="hidden-columns"
              style={{ ...columnStyle, flex: "auto" }}
            ></div>
            <div
              className="hidden-columns"
              style={{ ...columnStyle, flex: "auto" }}
            ></div>
          </div>
          <div style={dividerStyle}></div>
        </div>
        <div>
          <h1 style={{ paddingBottom: "25px", textAlign: "left" }}>
            User Experience -{" "}
            <span style={{ color: "rgb(122, 204, 0)" }}>Your Account</span>
          </h1>
          <div style={{ ...columnContainer, justifyContent: "center" }}>
            <div className="first-column-style" style={{ flex: "auto" }}>
              <img src={image1} style={imageStyle} alt="Phone" />
              <p style={textStyle}>
                Select the hamburger menu on the top left of the home screen
              </p>
            </div>
            <div className="first-column-style" style={{ flex: "auto" }}>
              <img src={image11} style={imageStyle} alt="Phone" />
              <p style={textStyle}>
                View account details by selecting ‘Your Account’
              </p>
            </div>
            <div className="first-column-style" style={{ flex: "auto" }}>
              <img
                className="voucher-confirmed"
                src={image12}
                style={imageStyle}
                alt="Phone"
              />
              <p style={textStyle}>Account details are displayed</p>
            </div>
            <div
              className="hidden-columns"
              style={{ ...columnStyle, flex: "auto" }}
            ></div>
            <div
              className="hidden-columns"
              style={{ ...columnStyle, flex: "auto" }}
            ></div>
            <div
              className="hidden-columns"
              style={{ ...columnStyle, flex: "auto" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TheApp;

/*

        <div>
          <h1 style={{ paddingBottom: '25px', textAlign: 'left' }}>User Experience - <span style={{color:'rgb(122, 204, 0)'}}> Expired Vouchers</span></h1>
          <div style={{ ...columnContainer, justifyContent: 'center' }}>
            <div className='first-column-style' style={{ flex: 'auto' }}>
              <img src={image1} style={imageStyle} alt="Phone" />
              <p style={textStyle}>Select to view expired vouchers</p>
            </div>
            <div className='first-column-style' style={{ flex: 'auto' }}>
              <img src={image8} style={imageStyle} alt="Phone" />
              <p style={textStyle}>Select an expired voucher</p>
            </div>
            <div className='first-column-style' style={{ flex: 'auto' }}>
              <img src={image9} style={imageStyle} alt="Phone" />
              <p style={textStyle}>Expired voucher details are displayed</p>
            </div>
            <div style={{ ...columnStyle, flex: 'auto' }}></div>
            <div style={{ ...columnStyle, flex: 'auto' }}></div>
          </div>
          <div style={dividerStyle}></div>
        </div>

        */

/* 
         <p>
            SkipSpace coordinates with security and skip companies to set up supervised sites
            in hotspots identified by the council, providing controlled, monitored waste management.
          </p>
          <p style={{ paddingBottom: '15px'}}>
            Working closely with local councils, SkipSpace can support with marketing campaigns to
            raise awareness of the brand.
          </p>

        */

          /*
  <p>
            Download the SkipSpace App through the{" "}
            <a
              className="download-links"
              target="blank"
              href="https://apps.apple.com/gb/app/skipspace/id6476873767"
            >
              App Store
            </a>{" "}
            or {" "}
            <a
              className="download-links"
              target="blank"
              href="https://play.google.com/store/apps/details?id=com.stmdeveloper.skipspaceapp"
            >
              Play Store
            </a>
            .
          </p>
          */

