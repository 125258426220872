
import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Home, OurImpact, OurSolution, TheApp, Contact, SkipCompanyDetails, CouncilAccountDetails, SkipSiteAddress, SkipspacePortal, SkipspacePortalDashboard, PrivacyPolicy, CookiePolicy } from './pages';
import { ChangeProfileName, Footer, ForgotPassword, VerifyEmail, ChangePassword, ChangeEmail, EmailChanged, NotFound } from './components';


function ScrollToTop() {
  const { pathname } = useLocation();
  // Scroll to the top instantly whenever the pathname changes
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null; // This component doesn't render anything
}


function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/our-impact" element={<OurImpact />} />
        <Route path="/our-solution" element={<OurSolution />} />
        <Route path="/the-app" element={<TheApp />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/skipspace-portal" element={<SkipspacePortal />} />
        <Route path="/skipspace-portal-dashboard" element={<SkipspacePortalDashboard />} />
        <Route path="/skip-company-details" element={<SkipCompanyDetails />} />
        <Route path="/council-account-details" element={<CouncilAccountDetails />} />
        <Route path="/change-profile-name" element={<ChangeProfileName />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/change-email" element={<ChangeEmail />} />
        <Route path="/email-changed" element={<EmailChanged />} />
        <Route path="/skip-site-address" element={<SkipSiteAddress />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;



/*
import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Home, OurImpact, HowItWorks, Contact, Login, SkipCompanyDetails, CouncilAccountDetails, SkipSiteAddress, SkipspacePortal, SkipspacePortalDashboard, PrivacyPolicy, CookiePrivacy } from './pages';
import { ChangeProfileName, Footer, ForgotPassword, VerifyEmail, ChangePassword, ChangeEmail, EmailChanged, NotFound } from './components';
import { useNavigate } from 'react-router-dom';

function App() { 
  const storedAuthStatus = sessionStorage.getItem('authenticated');
  const [authenticated, setAuthenticated] = useState(storedAuthStatus === 'true');
  const correctPassword = process.env.REACT_APP_LOGIN_PASSWORD;
  const navigate = useNavigate();

  function ScrollToTop() {
    const { pathname } = useLocation();
    // Scroll to the top instantly whenever the pathname changes
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null; // This component doesn't render anything
  }

  useEffect(() => {
    // Save the authentication status to sessionStorage whenever it changes
    sessionStorage.setItem('authenticated', authenticated);
  }, [authenticated]);

  const authenticate = (password) => {
    if (password === correctPassword) {
      setAuthenticated(true);
      navigate('/');
    } else {
      setAuthenticated(false);
      alert('Incorrect password. Access denied.');
    }
  };

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={authenticated ? <Home /> : <Navigate to="/Login" />} />
        <Route path="/Login" element={<Login authenticate={authenticate}  />} />
        <Route path="/our-impact" element={authenticated ? <OurImpact /> : <Navigate to="/Login" />} />
        <Route path="/how-it-works" element={authenticated ? <HowItWorks /> : <Navigate to="/Login" />} />
        <Route path="/contact" element={authenticated ? <Contact /> : <Navigate to="/Login" />} />
        <Route path="/skipspace-portal" element={authenticated ? <SkipspacePortal /> : <Navigate to="/Login" />} />
        <Route path="/skipspace-portal-dashboard" element={authenticated ? <SkipspacePortalDashboard /> : <Navigate to="/Login" />} />
        <Route path="/skip-company-details" element={authenticated ? <SkipCompanyDetails /> : <Navigate to="/Login" />} />
        <Route path="/council-account-details" element={authenticated ? <CouncilAccountDetails /> : <Navigate to="/Login" />} />
        <Route path="/forgot-password" element={authenticated ? <ForgotPassword /> : <Navigate to="/Login" />} />
        <Route path="/verify-email" element={authenticated ? <VerifyEmail /> : <Navigate to="/Login" />} />
        <Route path="/change-profile-name" element={authenticated ? <ChangeProfileName /> : <Navigate to="/Login" />} />
        <Route path="/change-password" element={authenticated ? <ChangePassword /> : <Navigate to="/Login" />} />
        <Route path="/change-email" element={authenticated ? <ChangeEmail /> : <Navigate to="/Login" />} />
        <Route path="/email-changed" element={authenticated ? <EmailChanged /> : <Navigate to="/Login" />} />
        <Route path="/skip-site-address" element={authenticated ? <SkipSiteAddress /> : <Navigate to="/Login" />} />
        <Route path="/privacy-policy" element={authenticated ? <PrivacyPolicy /> : <Navigate to="/Login" />} />
        <Route path="/cookie-policy" element={authenticated ? <CookiePolicy /> : <Navigate to="/Login" />} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
*/