import React, {useState}  from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { SignIn, Register } from '.';
import Header from './Header';

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  minHeight: '80vh',
  paddingTop: '50px',
  paddingBottom:'50px',
};

const linkContainerStyle = {
  textAlign: 'center',
  padding: '25px',
};

const linkStyle = {
  cursor: 'pointer',
  fontSize: '40px',
  margin: '10px',
  textDecoration: 'none',
  color: '#000000',
  fontFamily:'tungsten-semibold'
};

const activeLinkStyle = {
  textDecoration: 'underline',
  textUnderlinePosition: 'under',
  textDecorationColor:'rgb(122, 204, 0)',
};

function EmailChanged() {

  const [activeTab, setActiveTab] = useState('signIn');

  const switchToRegister = () => {
    setActiveTab('register');
  };

  const switchToSignIn = () => {
    setActiveTab('signIn');
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <div style={linkContainerStyle}>
          <div>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent:'center' }}>
          <h1>Email verification sent</h1>
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginLeft: '10px', fontSize:'25px' }} />
        </div>
          <h1>Please verify your new email...</h1>
          </div>
          <div style={{ borderBottom: '2px solid #003A49', margin: 'auto', width:"500px", maxWidth:"100%", paddingTop:"25px" }}></div>
        </div>
        <div>
        <div >
        <div style={linkContainerStyle}>
          <span
            style={activeTab === 'register' ? { ...linkStyle, ...activeLinkStyle } : linkStyle}
            onClick={switchToRegister}
          >
            Register
          </span>
          <span
            style={activeTab === 'signIn' ? { ...linkStyle, ...activeLinkStyle } : linkStyle}
            onClick={switchToSignIn}
          >
            Sign In
          </span>
        </div>
        <div>
          {activeTab === 'signIn' ? (
            <SignIn switchToRegister={switchToRegister} />
          ) : (
            <Register />
          )}
        </div>
      </div>
    </div>
    </div>
    </div>
  );
}

export default EmailChanged;
